import React from "react"
import PostListItem from "./Item"
import { Post } from "../../types/Post"

interface Props {
  posts: Post[]
}

const PostList = ({ posts }: Props) => {
  return (
    <ul>
      {posts.map((item, i) => {
        return <PostListItem key={i} {...item} />
      })}
    </ul>
  )
}

export default PostList
