import { Link } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import React from "react"
import { Post } from "../../../types/Post"
import Tags from "../../Tags"
import "./index.scss"

const PostListItem = ({
  excerpt,
  uri,
  date,
  title,
  tags,
  featuredImage,
}: Post) => {
  const _featuredImage = {
    fluid: featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: featuredImage?.node?.alt || "",
  }

  return (
    <Link to={uri}>
      <li className="postItemWrapper">
        <div className="inner">
          <div className="top">
            <div className="left">
              <div className="image">
                {_featuredImage?.fluid && (
                  <Image
                    className="img"
                    fluid={_featuredImage.fluid}
                    alt={_featuredImage.alt}
                  />
                )}
              </div>
              <div className="meta">
                <div className="title">
                  <span>{`${title}`}</span>
                </div>
                <div className="subtext">
                  <Tags tags={tags} className="tags" />
                  <div className="date">{date}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="excerpt">{parse(excerpt)}</div>
        </div>
      </li>
    </Link>
  )
}

export default PostListItem
